import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EsmInvoiceFinder } from '../invoice-finder.model';
import { Message } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { EsmStatesEnum, esmStatesToLabelMaps } from '../../dashboard/esm-dashboard.model';
import { Dispute } from '@common/deals/deals.model';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'cms-esm-invoice-dispute-dialog',
  templateUrl: './invoice-dispute-dialog.component.html',
  styleUrls: ['./invoice-dispute-dialog.component.scss']
})
export class InvoiceDisputeDialogComponent implements OnInit, OnChanges {
  @Input() invoice: EsmInvoiceFinder;
  @Input() display = false;
  @Input() messages: Message[] = [];
  @Input() headerText = 'Invoice Dispute';
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<Message[]>();
  @Output() dispute = new EventEmitter<Dispute>();
  errors: {[key: string]: ValidationErrors} = {};
  validInvoice: boolean;
  invoiceId: string = '';
  form: UntypedFormGroup;
  invoiceInfo: string = '';
  disableDispute: boolean = false;


  constructor(private formBuilder: UntypedFormBuilder,
              private decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    this.validInvoice = this.invoice && (this.invoice.state === esmStatesToLabelMaps[EsmStatesEnum.PENDING] || this.invoice.state === esmStatesToLabelMaps[EsmStatesEnum.MATCHED]);
    if (!this.validInvoice) {
      this.messages = [{
        severity: 'warn',
        summary: '',
        detail: 'The selected invoice should be in state "MATCHED" or "PENDING".'
      }];
    } else {
      this.invoiceInfo = this.invoice.documentId + ', Due date: ' + this.invoice.paymentDate + ', Gross amount: ' + this.decimalPipe.transform(this.invoice.totalGrossAmount, '1.2-2') + ' ' + this.invoice.totalGrossAmountCurrency;
    }
    this.form = this.formBuilder.group({
      note: ''
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.messages) {
      this.errors = {};
      if (this.messages) {
        this.messages.forEach((obj: Message) => {
          const { key: controlName } = obj;
          this.errors[controlName] = { 'server': true };
        });
      }
    }
  }

  onConfirmDispute(): void {
    let invoiceId: number;
    const { note } = this.form.value;
    if(!note) {
      this.messages = [{
        severity: 'error',
        summary: '',
        detail: 'The deposit of a note is obligatory.'
      }];
    } else if (this.validInvoice) {
      invoiceId = this.invoice.id;
      this.disableDispute = true;
      this.dispute.emit( {invoiceId, note} );
    }
  }

  onCancel(): void {
    this.hide();
  }

  onVisibleChange(visible: boolean): void {
    if (!visible) {
      this.onCancel();
    }
  }

  private hide(): void {
    this.messages = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.cancel.emit();
  }

}
