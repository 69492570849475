<cms-table-toast [selected]="selected"></cms-table-toast>

<section class="padding-12">
  <p-messages [value]="messages$ | async" [closable]="true"></p-messages>
  <cms-dashboard-deal-nav *ngIf="(viewType$ | async) === tableViewType.TABLE_DATA && (stateNames$ | async)"
                          [data]="stateNames$ | async"
                          [state]="state$ | async"
                          (back)="backToDashboard()"
                          (changeState)="changeState($event)">
  </cms-dashboard-deal-nav>
  <div class="row">
    <div class="col-8 col-xl-2 col-lg-8 col-md-8">
      <cms-esm-role-by-filter [value]="invoiceRole$ | async"
                              [disabled]="loading$ | async"
                              (onChange)="onRoleByFilter($event)">
      </cms-esm-role-by-filter>
    </div>
    <div class="col-12 col-xl-8 col-lg-12 col-md-12">
      <cms-invoice-filter *ngIf="filters$ | async"
                          (reset)="onReset()"
                          (filter)="onFilter($event)"
                          [filters]="filters$ | async"
                          [commodities]="commodities$ | async"
                          [counterParties]="counterParties$ | async"
                          [deliveryPoints]="deliveryPoints$ | async"
                          [invoiceTypes]="invoiceTypes$ | async"
                          [isMultiAgreeVisible]="isMultiAgreeVisible">
      </cms-invoice-filter>
    </div>
  </div>
  <!-- buckets table -->
  <cms-datatable *ngIf="(viewType$ | async) === tableViewType.BUCKETS && (data$ | async)?.values"
                 [paginator]="false"
                 [data]="(data$ | async)?.values"
                 [tableColumns]="columns$ | async"
                 [autoLayout]="true"
                 [scrollable]="false"
                 [loading]="loading$ | async"
                 [lazyLoadOnInit]="false"
                 selectionMode="single"
                 class="margin-15">
    <ng-template pTemplate="customHeader">
      <tr>
        <ng-container *ngFor="let col of additionalHeader">
          <th [attr.colspan]="col.colspan" [ngStyle]="col.style">
            {{ col.title }}
          </th>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let col of (columns$ | async)">
          <th *ngIf="col.visible" [ngStyle]="col.style">
            {{ col.title }}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td class="td-active" *ngIf="column.type === 'state'" [ngStyle]="column.style" (click)="onClick(row, column)">
        <span>{{ stateValuesMapping[row[column.name]] ? stateValuesMapping[row[column.name]] : row[column.name] }}</span>
      </td>
      <td class="td-active" *ngIf="column.type === 'esm'" [ngStyle]="column.style" (click)="onClick(row, column)">
        {{ row.bucketValues[column.name] }}
      </td>
    </ng-template>
  </cms-datatable>


  <!-- dataTable table -->
  <cms-datatable *ngIf="(viewType$ | async) === tableViewType.TABLE_DATA && (data$ | async)?.values"
                 [currentPage]="(paging$ | async)?.page"
                 [tableColumns]="columns$ | async"
                 [sortField]="(sorting$ | async)?.columnName"
                 [sortOrder]="(sorting$ | async)?.sortOrder"
                 [loading]="loading$ | async"
                 [data]="(data$ | async)?.values"
                 [totalRecords]="(data$ | async)?.count"
                 [scrollable]="false"
                 [autoLayout]="true"
                 [lazyLoadOnInit]="false"
                 (lazyLoad)="onLazyLoad($event)"
                 [checkboxRow]="true"
                 [selected]="this.selected$ | async"
                 (selectedChange)="onSelected($event)"
                 class="margin-15">
    <ng-template pTemplate="caption">
      <div class="float-left date-label">{{ dateRangeText$ | async }}</div>
      <cms-deal-table-caption (openAddNoteDialog)="onOpenAddNoteDialog()"
                              (exportEvent)="onExport()"
                              (clickCustomize)="onClickCustomize()"
                              [exportDisable]="!(data$ | async)?.values?.length"
                              [addNoteDisable]="!(selectedIds$ | async)?.length"
                              [multiAgreeDisable]="!(selectedIds$ | async).length"
                              [showMultiAgreeButton]="isMultiAgreeVisible"
                              (openAgreeDialog)="onOpenMultiAgreeDialog()">
      </cms-deal-table-caption>
    </ng-template>
    <ng-template pTemplate="body" let-row="row" let-column="column">
      <td *ngIf="column.type === 'button' && column.name === 'openDocument'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a target="_blank"
             id="db-invoicefinder-html"
             routerLink="/esm/invoicefinder/esm_document"
             [queryParams]="{ id: row.id }">
            <button pButton type="button" icon="fa fa-file" title="Document"></button>
          </a>
        </div>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'openDocumentXml'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <button pButton type="button" icon="fa fa-file-code-o" title="Document (XML)" (click)="onDownloadXml(row)"></button>
        </div>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'misMatch'">
        <cms-esm-mis-match-button class="comp-match-cell" [row]="row" route="/esm/compare/invoice"></cms-esm-mis-match-button>
      </td>
      <!-- pdf button-->
      <td *ngIf="column.type === 'button' && column.name === 'pdf'">
        <div class="btn-wrapper inline-block" *ngIf="row.pdfButtonVisible">
          <button pButton type="button" icon="fa fa-file-pdf-o" title="Document (PDF)" (click)="onDownloadPdf(row)"></button>
        </div>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'resend'">
        <div class="btn-wrapper inline-block" *ngIf="row.resendButtonVisible">
          <button pButton class="ui-button-resend-pdf" type="button" iconPos="right" icon="fa fa-solid fa-envelope" title="Resend Document (PDF)" (click)="resendPDF(row)" id="esmDashboardResendButton"></button>
        </div>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'nearMatch'" [ngStyle]="column.style">
        <cms-esm-near-match-button class="comp-match-cell"
                                   [row]="row"
                                   route="/esm/match/invoice">
        </cms-esm-near-match-button>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'notes'" [ngStyle]="column.style">
        <cms-notes-cell [confirmationId]="row.id"
                        [data]="row"
                        [notes]="row.notes"
                        (view)="onViewNotes($event)">
        </cms-notes-cell>
      </td>
      <td *ngIf="column.type === 'button' && column.name === 'auditTrail'" [ngStyle]="column.style">
        <div class="btn-wrapper inline-block">
          <a routerLink="/esm/audittrail/invoice" title="Audit Trail" target="_blank" [queryParams]="getDisplayParams(row)">
            <button pButton type="button" icon="fa fa-search" title="Audit Trail"></button>
          </a>
        </div>
      </td>
      <!--      agree button-->
      <td *ngIf="column.type === 'button' && column.name === 'agree'">
        <div class="btn-wrapper inline-block" *ngIf="row.agreeButtonVisible">
          <button pButton class="btn" type="button" title="Agree" (click)="agreeItem(row)" label="Agree"></button>
        </div>
      </td>
      <!--      dispute button-->
      <td *ngIf="column.type === 'button' && column.name === 'dispute'">
        <div class="btn-wrapper inline-block" *ngIf="row.disputeButtonVisible">
          <button pButton class="btn" type="button" title="Dispute Invoice" (click)="disputeItem(row)" label="Dispute"></button>
        </div>
      </td>
    </ng-template>
  </cms-datatable>
</section>
<section>
  <cms-add-note-dialog *ngIf="(dialog$ | async)?.name === 'ADD_NOTES'"
                       [display]="(dialog$ | async)?.name === 'ADD_NOTES'"
                       [messages]="(dialog$ | async)?.messages"
                       [confirmationIds]="selectedIds$ | async"
                       [headerText]="'Add note to invoices'"
                       (cancel)="onCancelNotesDialog()"
                       (save)="onConfirmAddNotes($event)">
  </cms-add-note-dialog>
  <cms-view-confirmation-notes-dialog *ngIf="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [display]="(dialog$ | async)?.name === 'VIEW_NOTES'"
                                      [messages]="(dialog$ | async)?.messages"
                                      [data]="(dialog$ | async)?.data"
                                      [headerText]="'View invoice notes'"
                                      (cancel)="onCancelNotesDialog()"
                                      (save)="onConfirmAddNotes($event)">
  </cms-view-confirmation-notes-dialog>
  <cms-esm-invoice-agree-dialog *ngIf="(dialog$ | async)?.name === 'AGREE'"
                                [display]="(dialog$ | async)?.name === 'AGREE'"
                                [messages]="(dialog$ | async)?.messages"
                                [headerText]="'Invoice Agreement'"
                                [invoices]="(dialog$ | async)?.data"
                                (cancel)="onCancelAgree()"
                                (agree)="onConfirmAgree($event)">
  </cms-esm-invoice-agree-dialog>
  <cms-esm-invoice-dispute-dialog *ngIf="(dialog$ | async)?.name === 'DISPUTE'"
                                  [display]="(dialog$ | async)?.name === 'DISPUTE'"
                                  [messages]="(dialog$ | async)?.messages"
                                  [headerText]="'Invoice Dispute'"
                                  [invoice]="(dialog$ | async)?.data"
                                  (cancel)="onCancelDispute()"
                                  (dispute)="onConfirmDispute($event)">
  </cms-esm-invoice-dispute-dialog>
</section>
