import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';
import { RemitTransactionFilterModel } from '../err/remit/transactions/filter/remit-transactions-filter.model';
import { RemitSearchModel } from '../err/remit/transactions/search/remit-transactions-search.model';
import { EcmDashboardPropertiesFilters } from '../ecm/ecm-dashboard/ecm-dashboard.model';

@Injectable()
export class StateService extends CookieService {
  private readonly cookieNameAppState = 'appState';

  appState: AppState = {};

  constructor() {
    super();
  }

  get loginRoute(): string {
    this.loadState();
    return this.appState.loginRoute;
  }

  setLoginRoute(route: string) {
    this.loadState();
    this.appState.loginRoute = route;
    this.saveState();
  }

  get organisationId(): number {
    this.loadState();
    return this.appState.organisationId;
  }

  setOrganisationId(organisationId: any) {
    this.loadState();
    this.appState.organisationId = organisationId;
    this.saveState();
  }

  reset() {
    this.appState = {};
    this.deleteState();
  }

  resetOrganisationId() {
    this.loadState();
    delete this.appState.organisationId;
    this.saveState();
  }

  private loadState() {
    const state = this.get(this.cookieNameAppState);
    this.appState = state ? JSON.parse(atob(state)) : {};
  }

  private saveState() {
    this.setCookie(this.cookieNameAppState, btoa(JSON.stringify(this.appState)), 1);
    this.setCookie('auth-session-token', this.get('XSRF-TOKEN'), 1);
  }

  private deleteState() {
    this.deleteCookie(this.cookieNameAppState);
    this.deleteCookie('XSRF-TOKEN');
    this.deleteCookie('auth-session-token');
    sessionStorage.clear();
  }


  // ====== err & ecm
  cacheState(state: any, index: string): void {
    sessionStorage.setItem(index, JSON.stringify(state));
  }

  getCache(index: string): RemitTransactionFilterModel | EcmDashboardPropertiesFilters {
    return JSON.parse(sessionStorage.getItem(index));
  }

  setRemitTransactionSearchMask(state: RemitSearchModel, documentType: string) {
    sessionStorage.setItem(documentType, JSON.stringify(state));
  }

  getRemitTransactionSearchMask(documentType: string): RemitSearchModel {
    return JSON.parse(sessionStorage.getItem(documentType)) || new RemitSearchModel();
  }
}

interface AppState {
  authenticationToken?: string;
  organisationId?: number;
  loginRoute?: string;
}
